import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../styles/FAQ.css";
import Question from "./Question";
import { v4 as uuidv4 } from "uuid";

function FAQ() {
    return (
        <div className="App">
            <title>FAQs: Answers to Your OnCloudPDF Queries</title>
            <Header />
            <div className="faq">
                <h1>Frequently Asked Question</h1>
                <h2>At OnCloudPDF, we understand that clarity and ease of use are paramount. Whether you're a new user exploring our platform or a returning visitor seeking answers, our Frequently Asked Questions (FAQ) section is designed to provide you with comprehensive information.</h2>
                <Question question="Is OnCloudPDF free to use?"
                    answer="Yes, OnCloudPDF is completely free to use. We believe in providing essential PDF management tools without any cost to our users."
                    id={uuidv4()} />
                <Question question="What is OnCloudPDF?"
                    answer="OnCloudPDF is a free online PDF management platform that allows users to convert, compress, and edit PDF files. Our mission is to provide a seamless user experience, with a focus on simplicity, accessibility, and efficiency."
                    id={uuidv4()} />
                <Question question="How can I convert a PDF file using OnCloudPDF?"
                    answer="To convert a PDF file, simply visit our website, upload your file, and choose the desired output format. OnCloudPDF will handle the rest."
                    id={uuidv4()} />
                <Question question="Can I compress PDF files on OnCloudPDF?"
                    answer={<><b>Absolutely.</b> OnCloudPDF allows you to compress PDF files to reduce their size without compromising on quality.</>}
                    id={uuidv4()} />
                <Question question="Is an account required to use OnCloudPDF services?"
                    answer="No account is required. OnCloudPDF is designed to be user-friendly, and you can use our services without the need for registration."
                    id={uuidv4()} />
                <Question question="How secure is OnCloudPDF with my documents?"
                    answer="We prioritize the security and privacy of your documents. OnCloudPDF uses secure protocols, and all uploaded files are deleted after a certain period."
                    id={uuidv4()} />
                <Question question="What formats can I convert my PDF files to?"
                    answer="OnCloudPDF supports a variety of output formats for conversion, including popular options like Word, Excel, and image formats."
                    id={uuidv4()} />
                <Question question="Are there any file size limitations for uploads?"
                    answer="While we do have some limitations on file size, OnCloudPDF is designed to handle a wide range of file sizes. Check our guidelines for specific details."
                    id={uuidv4()} />
                <Question question="How can I provide feedback or get support for OnCloudPDF?"
                    answer="We value user feedback. If you have any questions, concerns, or suggestions, please visit our support page or contact us directly. Your input is important to us."
                    id={uuidv4()} />
                <Question question="How does OnCloudPDF ensure the security of confidential documents?"
                    answer="OnCloudPDF employs industry-standard encryption protocols to secure your confidential documents during both upload and processing. Our infrastructure is designed to prioritize data privacy and protection."
                    id={uuidv4()} />
                <Question question="Can I trust OnCloudPDF with sensitive business documents?"
                    answer={<><b>Absolutely.</b> OnCloudPDF takes the security of sensitive business documents seriously. Our platform is designed with robust security measures to safeguard confidential information.</>}
                    id={uuidv4()} />
                <Question question="What happens to my uploaded files after processing?"
                    answer="We automatically delete all uploaded files after a specified period. Your files are not stored on our servers, ensuring that your confidential information remains secure and private."
                    id={uuidv4()} />
                <Question question="Does OnCloudPDF have access to the content of my uploaded documents?"
                    answer="No, OnCloudPDF does not access or view the content of your uploaded documents. Our commitment is to respect user privacy, and we do not store or analyze the content of files beyond the processing period."
                    id={uuidv4()} />

            </div>
            <Footer />
        </div>
    );

}

export default FAQ;