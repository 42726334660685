import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormPDF from "./FormPDF";

function PdfToScan() {


    return (
        <div className="App">
          <title>Transform PDFs to Scans - Free PDF to Realistic Scanned Document</title>
          <Header/>
          <FormPDF/>
          <Footer/>
        </div>
    );
}

export default PdfToScan;