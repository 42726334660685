import React from "react";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import '../styles/UploadForm.css';

function UploadForm({url, handleDivClick, title, description, button, behindButton}) {

    const newurl = "https://onlypdf.com"+url;

    const onClickShareLink = (link) => {
        window.open(link, '_blank');
    }

    return (
        <div className='set_button_select'>
            <h1 className='select_pdf_h1'>{title}</h1>
            <h2 className='select_pdf_h2'>{description}</h2>
            <div className="button_and_links">
                <button className="button_select_file" onClick={handleDivClick}>{button}</button>
                <p className="behind_button">{behindButton}</p>
                <div className='share-buttons'>
                    <Tooltip title="Share on Facebook" position="bottom" animation="scale" arrow>
                        <button data-tippy-arrow="true" onClick={() => onClickShareLink("https://www.facebook.com/sharer/sharer.php?u=" + newurl)} target="_blank" className="facebook"></button>
                    </Tooltip>
                    <Tooltip title="Share on Twitter" position="bottom" animation="scale" arrow>
                        <button onClick={() => onClickShareLink("https://twitter.com/intent/tweet?url=" + newurl + "&text=Descripción")} target="_blank" className="twitter"></button>
                    </Tooltip>
                    <Tooltip title="Share on Linkedin" position="bottom" animation="scale" arrow>
                        <button onClick={() => onClickShareLink("https://www.linkedin.com/shareArticle?url=" + newurl)} target="_blank" className="linkedin"></button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

export default UploadForm;