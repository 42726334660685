import React, { useEffect, useState } from "react";
import mergePDFimg from '../../icons/merge.svg';
import splitPDFimg from '../../icons/split.svg';
import rotatePDFimg from '../../icons/rotate.svg';
import SelectToolButton from "./SelectToolButton";
import '../styles/Row.css';


function OrganizeRow() {

    const [overflow, setOverflow] = useState(false);

    const leftArrowClick = () => {
        let scrollDiv = document.getElementById('scroll_div_org');
        scrollDiv.scrollTo({
            left: scrollDiv.scrollLeft - 150,
            behavior: 'smooth'
        });
    }
    const rightArrowClick = () => {
        let scrollDiv = document.getElementById('scroll_div_org');
        scrollDiv.scrollTo({
            left: scrollDiv.scrollLeft + 150,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        let scrollDive = document.getElementById('scroll_div_org');
        let selec_div = document.getElementById('selection_div_org');
        if (scrollDive.scrollWidth > selec_div.scrollWidth) {
            setOverflow(true);
        } else {
            setOverflow(false);
        }
    }, []);

    return (
        <div className='pdf_row_div'>
            <label className='pdf_label'>Organize PDF</label>
            <div className='selection_div' id="selection_div_org">
                <div className='scrollable_div' id="scroll_div_org">
                    <SelectToolButton
                        label='Merge PDF'
                        source={mergePDFimg}
                        altText="Merge PDF"
                        link="/merge"
                    />
                    <SelectToolButton
                        label='Split PDF'
                        source={splitPDFimg}
                        altText="Split PDF"
                        link="/split"
                    />
                    <SelectToolButton
                        label='Rotate PDF'
                        source={rotatePDFimg}
                        altText="Rotate PDF"
                        link="/rotate"
                    />
                </div>
                {overflow && <button className="leftArrow" onClick={leftArrowClick}></button>}
                {overflow && <button className="rightArrow" onClick={rightArrowClick}></button>}
                

            </div>
        </div>
    );
}

export default OrganizeRow;