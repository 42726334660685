import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../styles/LegalCookies.css";

function Cookies() {
    return (
        <div>
            <title>Privacy Policy and Cookie Policy of OnCloudPDF S.L.</title>
            <Header />
            <div className="legal_cookies_div_father">
                <div className="legal_cookies_div">
                    <h1>Privacy Policy of OnCloudPDF S.L.</h1>

                    <p><strong>Effective Date:</strong> 03/11/2023 </p>
                    <p>Thank you for visiting OnCloudPDF S.L. At OnCloudPDF S.L., we respect your privacy and are committed to protecting any personal information you may provide during your visit to our website.</p>

                    <h2>Collection of Personal Information</h2>
                    <p>At OnCloudPDF S.L., we do not collect or store personal information from our users through our website. We do not require you to provide your name, email address, or other personal information to use our PDF management tools.</p>

                    <h2>Use of Cookies</h2>
                    <p>Our website uses cookies to enhance the user experience. Cookies are small text files that are stored on your device when you visit our website. These cookies help us gather anonymous statistics about website usage and improve its functionality.</p>

                    <h2>Third Parties and External Links</h2>
                    <p>OnCloudPDF S.L. may contain links to third-party websites or integrate third-party services, such as social media buttons. We have no control over the privacy policies of these third parties and are not responsible for how they collect or use your information.</p>

                    <h2>Changes to the Privacy Policy</h2>
                    <p>OnCloudPDF S.L. reserves the right to update or modify this privacy policy at any time. Changes will take effect immediately after being posted on this page. We recommend that you periodically review this policy to stay informed about any changes.</p>

                    <h2>Questions and Contact</h2>
                    <p>If you have any questions or concerns related to our privacy policy, please do not hesitate to contact us at the following email address: tll.developers.apps@gmail.com</p>

                    <p>Thank you for trusting OnCloudPDF S.L.</p>

                    <div className="policy_legal_h1">
                        <h1>Cookie Policy of OnCloudPDF S.L.</h1>
                    </div>

                    <p><strong>Effective Date:</strong> 03/11/2023 </p>
                    <h2>What are Cookies?</h2>
                    <p>Cookies are small text files that are stored on your device when you visit websites. These cookies contain anonymous information that is used to improve your online experience and website functionality.</p>

                    <h2>Cookies Used by OnCloudPDF S.L.</h2>
                    <p>At OnCloudPDF S.L., we use cookies for the purpose of:</p>
                    <ul>
                        <li>Collecting anonymous statistics about website usage.</li>
                        <li>Enhancing the functionality and performance of the site.</li>
                        <li>Remembering user preferences during their visit.</li>
                    </ul>

                    <h2>Cookie Control</h2>
                    <p>You can control and manage cookies in your web browser. Most web browsers allow you to accept, reject, or delete cookies. Please note that by disabling certain cookies, some features of our website may not function properly.</p>

                    <h2>Changes to the Cookie Policy</h2>
                    <p>OnCloudPDF S.L. reserves the right to update or modify this cookie policy at any time. Changes will take effect immediately after being posted on this page. We recommend that you periodically review this policy to stay informed about any changes.</p>

                    <h1>Google Analytics Policy</h1>

                    <h2>Google Analytics</h2>
                    <p>OnCloudPDF S.L. uses Google Analytics to collect information about how visitors use our website. Google Analytics uses cookies to collect this information. The data collected is used to generate reports and help us improve our website.</p>
                    <p>If you wish to opt-out of Google Analytics tracking, you can use the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.</p>

                    <h2>Google Ads Policy</h2>

                    <h2>Google Ads</h2>
                    <p>OnCloudPDF S.L. may use Google Ads to display relevant advertisements. Google Ads uses cookies to serve ads based on your visit to our website and other sites on the Internet.</p>
                    <p>You can opt-out of personalized advertising by visiting Google&apos;s <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">Ad Settings</a>.</p>

                    <h2>Questions and Contact</h2>
                    <p>If you have any questions or concerns related to our cookie policy, please do not hesitate to contact us at the following email address: tll.developers.apps@gmail.com</p>

                    <p>Thank you for using OnCloudPDF S.L.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cookies;