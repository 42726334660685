import React, { useState } from "react";
import "../../styles/AddMoreFilesBubble.css";
import 'react-tippy/dist/tippy.css'; // Import Tippy.js styles
import 'tippy.js/animations/scale.css'; // Import Tippy.js animation styles
import { Tooltip } from 'react-tippy';
import "../../styles/LateralZone.css";

function AddMoreFilesBubble({ addMoreFiles, sortPDFPreviewAZ, sortPDFPreviewZA, merge }) {

    const [sortAZ, setSortAZ] = useState(true);

    const changeSortState = () => {
        setSortAZ(!sortAZ);
        if (sortAZ) {
            sortPDFPreviewAZ();
        } else {
            sortPDFPreviewZA();
        }
    };

    return (
        <div className="lateral_div" >
            <div className="principal_div_bubbles">
                <Tooltip title="Add more PDF's" position="top" animation="scale" arrow size="small">
                    <button className="button_add_more_files" onClick={addMoreFiles} />
                </Tooltip>
                {sortAZ ?
                    <Tooltip title="Sort from A to Z" position="left" animation="scale" arrow size="small">
                        <button className="button_a_to_z" onClick={changeSortState} />
                    </Tooltip> :
                    <Tooltip title="Sort from Z to A" position="left" animation="scale" arrow size="small">
                        <button className="button_z_to_a" onClick={changeSortState} />
                    </Tooltip>
                }
            </div>
            <div className="button_div">
                <Tooltip title="Merge PDF's" position="top" animation="scale" arrow size="small">
                    <button className="merge_button" onClick={merge}>Merge PDF's</button>
                </Tooltip>
            </div>
        </div>
    );
}

export default AddMoreFilesBubble;