import React from "react";
import "../styles/Footer.css";
import CookieConsent from "react-cookie-consent";

function Footer() {
    const year = new Date().getFullYear();
    const style_cookies = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor:"var(--primary-color)",
        margin: "10px",
        width: "98%",
        left: "0",
        borderRadius: "20px",
        fontWeight: "600",
    };

    const button_select_file = {
        display: 'inline-block',
        padding: '10px 20px',
        backgroundColor: 'var(--button-color)', // Use camelCase for CSS properties in JavaScript
        color: '#fff',
        border: '2px solid var(--button-color)',
        borderRadius: '15px', // Use camelCase for CSS properties in JavaScript
        textDecoration: 'none', // Use camelCase for CSS properties in JavaScript
        fontSize: 'large', // Use camelCase for CSS properties in JavaScript
        fontWeight: 'bold', // Use camelCase for CSS properties in JavaScript
        transition: 'background-color 0.5s, color 0.5s, transform 0.5s', // Use camelCase for CSS properties in JavaScript
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.3)', // Use camelCase for CSS properties in JavaScript
        cursor: 'pointer',
    };

    delete style_cookies.left;

    return (
        <div className="footer_div">
            <a className="footer_text" href="/">© OnCloudPDF {year} ® - Best PDF Tools</a>
            <div className="div_footer_links">
                <a className="footer_links" href="/legal">Legal Advertising</a>
                <a className="footer_links" href="/cookies">Privacy and Cookies Policy</a>
            </div>
            {/* <CookieConsent
                location="bottom"
                buttonText="Got it!"
                cookieName="myCookieConsent"
                className="cookie_consent"
                expires={365}
                style={style_cookies}
                buttonStyle={button_select_file}
            >
                We use cookies to ensure you get the best experience on our website. By continuing, you're agreeing to our use of cookies. Learn more in our <a href="/cookies" >Privacy and Cookies Policy</a>.
            </CookieConsent> */}
        </div>
    );
}

export default Footer;