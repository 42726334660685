import React from "react";
import "../styles/Progress.css";

function Progress({text, loadingPercentage}){

    return(
    <div className="upload-container">
        <div className="circle"></div>
        <div className="progress-text">{text}</div>
        <div className="progress-text">{loadingPercentage}%</div>
    </div>
    )
}

export default Progress;