import React from "react";
import "../styles/AboutUs.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import OnCloudLogo from "../../img/about_us_oncloud_logo.svg";

function AboutUs() {
    return (
        <div className="App">
            <title>Meet OnCloudPDF: Committed to Excellence</title>
            <Header />
            <div className="about_us">
                <h1 className="about_us_title">About Us</h1>
                <section id="journey">
                    <h2>Our Journey</h2>
                    <p>Welcome to the heart of innovation, where OnCloudPDF comes to life through the dedicated efforts of a dynamic group of developers. At OnCloudPDF, we believe in the transformative power of digital solutions, and our journey is a testament to our commitment to pushing boundaries.</p>
                </section>

                <section id="who-we-are">
                    <h2>Who We Are</h2>
                    <p>We are a diverse team of skilled developers, united by a passion for crafting exceptional web experiences. Our backgrounds range from seasoned professionals to young, ambitious talents, creating a blend of expertise and fresh perspectives.</p>
                </section>

                <section id="mission-vision">
                    <h2>Mission and Vision</h2>
                    <h3>Mission:</h3>
                    <p>To revolutionize online document management by providing cutting-edge web solutions that simplify, enhance, and elevate user experiences.</p>
                    <h3>Vision:</h3>
                    <p>To be the go-to platform for seamless PDF management, setting the standard for innovation, efficiency, and user satisfaction.</p>
                </section>

                <section id="our-expertise">
                    <h2>Our Expertise</h2>
                    <h3>Technical Prowess</h3>
                    <p>Our developers are equipped with a rich set of technical skills, spanning the latest web development languages, frameworks, and tools...</p>
                    <h3>Problem-Solving Champions</h3>
                    <p>No challenge is too big for our team. Whether it's optimizing page load speeds, enhancing user interfaces, or addressing security concerns, our developers are adept at identifying issues and crafting effective solutions. We thrive on solving complex problems to make OnCloudPDF a reliable and user-friendly platform.</p>
                    <h3>User-Centric Design</h3>
                    <p>Understanding the user experience is at the core of our development philosophy. We meticulously design and develop features with the end user in mind, ensuring that OnCloudPDF is intuitive, accessible, and enjoyable for all.</p>
                </section>

                <section id="our-commitment">
                    <h2>Our Commitment to Excellence</h2>
                    <p>Excellence is not just a goal; it's a standard that permeates everything we do. From the lines of code we write to the user interfaces we design, our commitment to excellence is unwavering. We continuously refine and improve OnCloudPDF to meet and exceed the evolving needs of our users.</p>
                </section>

                <section id="collab-inno">
                    <h2>Collaborative Innovation</h2>
                    <p>Our collaborative approach sets us apart. We foster an environment where creativity and innovation flourish. Every idea is valued, and every team member plays a crucial role in shaping the future of OnCloudPDF.</p>
                </section>

                <section id="join-us">
                    <h2>Join Us on this Journey</h2>
                    <p>As we continue to innovate and evolve, we invite you to join us on this exciting journey. Explore the capabilities of OnCloudPDF, and witness firsthand the dedication and passion that define our team of developers.</p>
                </section>
                <div className="img_div">
                <img src={OnCloudLogo} alt="OnCloudPDF Team"/>

                </div>
                <h2>Thank you for being a part of the OnCloudPDF story.💖</h2>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;