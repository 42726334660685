import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormPDF from "./FormPDF";

function PdfToDocx() {


    return (
        <div className="App">
          <title>Free PDF to Word Converter - Instant and Accurate Conversions</title>
          <Header/>
          <FormPDF/>
          <Footer/>
        </div>
    );
}

export default PdfToDocx;