import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './main/components/Main';
import Merge from './merge/components/Merge';
import Split from './split/components/Split';
import PdfToImg from './convert-from-pdf/pdf-to-img/components/Pdf-to-img';
import PdfToDocx from './convert-from-pdf/pdf-to-docx/components/Pdf-to-docx';
import Legal from './legal/components/Legal';
import Cookies from './cookies/components/Cookies';
import PdfToScan from './convert-from-pdf/pdf-to-scan/components/Pdf-to-scan';
import Rotate from './rotate/components/Rotate';
import AboutUs from './about_us/components/AboutUs';
import FAQ from './faq/components/FAQ';



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/merge" element={<Merge />} />
        <Route path="/split" element={<Split />} />
        <Route path="/rotate" element={<Rotate />} />
        <Route path="/pdf-to-img" element={<PdfToImg />} />
        <Route path="/pdf-to-docx" element={<PdfToDocx />} />
        <Route path="/pdf-to-scan" element={<PdfToScan />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/cookies" element={<Cookies />} />
      </Routes>
    </Router>
  </React.StrictMode>
);